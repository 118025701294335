import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_WhatHappens = () => {
    const seo = {
        metaDesc: 'What happens after filing a utility patent application? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What happens after filing a utility patent application? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What happens after filing a utility patent application?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2><b>What happens after filing a utility patent application?</b></h2>
                                <p><span style={{ fontWeight: 400 }}>The initial steps that the USPTO takes are similar to what it does for the
                                    PPA.&nbsp; Here’s a brief overview of the process:</span></p>
                                <ol>
                                    <li><span style={{ fontWeight: 400 }}> The USPTO receives your application and catalogs it.</span></li>
                                    <li><span style={{ fontWeight: 400 }}> It sends you a filing receipt. If you send it via regular mail, this is
                                        mailed to you if everything is in order.&nbsp; If you file electronically, a print-out that acknowledges
                                        receipt is available after you hit the “submit” button.&nbsp;&nbsp;</span></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The filing receipt includes information such as the inventor(s)’s names,
                                    invention’s title, which patent examining group will analyze your application, filing date, application’s
                                    serial number and the number of claims you are asserting.&nbsp; If there is anything incorrect here, you
                                    should send or fax a letter to the USPTO’s Application Branch. (k:&nbsp; this applicable to PPAs,
                                    too?)</span></p>
                                <ol start="3">
                                    <li><span style={{ fontWeight: 400 }}> For a utility patent application, a patent examiner will also look at the
                                        claims in the application.&nbsp; He/she will determine whether you qualify for a patent and if you do,
                                        what its scope should be.</span></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If the patent examiner rejects any claims, you and your lawyer should look at why
                                    this occurred and submit an amendment, responding to the rejection.</span></p>
                                <ol start="4">
                                    <li><span style={{ fontWeight: 400 }}> The patent examiner analyzes the amendment and either changes it to a
                                        Notice of Allowance, which is a patent application approval, or issues a final rejection of the claim.
                                        &nbsp;Learn more about Noice of Allowance and How to Handle Notice Of Allowance</span></li>
                                </ol>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_WhatHappens;